
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/index.vue?macro=true";
import { default as indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta } from "/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta?.name ?? "index",
    path: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta?.path ?? "/",
    props: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta?.props ?? false,
    meta: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta || {},
    alias: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta?.alias || [],
    redirect: indexWrfWp1y3aIDPri4NKsuDdVf7w6368FL3WrDANTbZ0f4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/index.vue")
  },
  {
    name: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta?.name ?? "lang",
    path: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta?.path ?? "/:lang?",
    props: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta?.props ?? false,
    meta: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta || {},
    alias: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta?.alias || [],
    redirect: indexRlWrhvK9SzIkFc6OrW8iOE6V0xolnH3BZfdspYN2MlAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta?.name ?? "lang-voucher-start",
    path: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta?.path ?? "/:lang?/voucher/start",
    props: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta?.props ?? false,
    meta: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta || {},
    alias: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta?.alias || [],
    redirect: startTJ0gEiD9IHreqQsp3lhs4b3ckIB2yywQi46K7ghpp1YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta?.name ?? "lang-purchase-start",
    path: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta?.path ?? "/:lang?/purchase/start",
    props: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta?.props ?? false,
    meta: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta || {},
    alias: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta?.alias || [],
    redirect: start0F35KfAGVYPnWc1IzS76VwjkGdKzKSkpuyl927NB32cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta?.name ?? "lang-service-booking-checkout",
    path: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta?.props ?? false,
    meta: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta || {},
    alias: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta?.alias || [],
    redirect: checkout9CI2CoFb5DwGgT02q_45BEF5Z3W0L5NAUkbMGxaCSp_45zsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta?.props ?? false,
    meta: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta || {},
    alias: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta?.alias || [],
    redirect: koiOQ84YUUC1OvAeUylJ6XWiGCo_450ik3MlIFnVwb65cjAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta?.props ?? false,
    meta: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta || {},
    alias: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta?.alias || [],
    redirect: confirmationUBiRcH_iBdGfs4wP2DZxEt31ly05tdXfna7h8s_45zCl8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta?.props ?? false,
    meta: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta || {},
    alias: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta?.alias || [],
    redirect: confirmationG7NlYWBCE69JfrEMFc2NwIUP1ih8VxbIaKKqncO9E44Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta?.props ?? false,
    meta: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta || {},
    alias: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta?.alias || [],
    redirect: buyerlSC4uZGVRBgzxssRr3Fmzz_45Y57oh_45OsO5APFYEkU8AUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta?.props ?? false,
    meta: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta || {},
    alias: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta?.alias || [],
    redirect: buyercQJaUniDH3p1jYmKRr6NdVnw6QMSgI_ADdIGdrZy5q4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta?.props ?? false,
    meta: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta || {},
    alias: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta?.alias || [],
    redirect: paymentvgKGtVdrHBOtPqbdvcjhcIbImcKOWE__45X6uEtqiwmH8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta?.props ?? false,
    meta: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta || {},
    alias: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta?.alias || [],
    redirect: challenge__QM2QQ6BcydmYCN3coRZIR4gx1AsvMJhHII2IiSZw8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta?.props ?? false,
    meta: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta || {},
    alias: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta?.alias || [],
    redirect: paymentv75NFrQZsVHU33pI68I9hINY_45KlfWiYfHKL_BaLclyUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/castilla-y-leon/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/leon/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/leon/astorga/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/leon/congosto/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/leon/leon/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/leon/santa-maria-del-paramo/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/benalmadena/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/estepona/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/fuengirola/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/malaga/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/marbella/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/mijas/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/ronda/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.path ?? "/spa-balneario/malaga/torremolinos/",
    props: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.props ?? false,
    meta: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta || {},
    alias: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.alias || [],
    redirect: SpaList_46pageGNrbq26xl2hJsxsbY5oJR16hrp6LE_8_7rEe0CXyZ_MMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/higueron-spa/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/calma-spa/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/alum-spa-maestranza/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/hydros-spa/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.path ?? "/spa-balneario/malaga/mood-spa/",
    props: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.props ?? false,
    meta: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta || {},
    alias: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.alias || [],
    redirect: SpaDetail_46pageKo2FM0MU2hwZTESfZnG_45PLiYF81U9rBJ833tfwotdHMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-aromatico-50-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/piel-de-seda/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/body-wrap/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/terapia-geotermal-con-piedras/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-pies/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/el-arte-de-crear/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-dinamico-de-recuperacion-corporal/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-terapeutico-50-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/tratamiento-de-limpieza-facial-purificante/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/peeling-corporal-con-cristales-marinos/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/ritual-anti-stress/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/baneras-de-inmersion-en-hielo/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-platinum-beach/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/tarjeta-regalo-importe-200e/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-circuito-spa-con-cava-y-bombones/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-presoterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-relax-unica-spa-masaje-relax-50/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-80/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-romantico/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-hidratacion-profunda-spa-tratamiento-facial-de-acido-hialuronico/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-25-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-50-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/facial-anti-aging-de-acido-hialuronico/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/aire-de-hadas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-ritual/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-50/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-50/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-25/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-25/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/passage-du-silence/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-90/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-60/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/facial-express/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-piel-de-seda/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-relajante-calma/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-detox/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma-para-2/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan-para-2/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-en-pareja/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-de-espalda/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-indio-de-cabeza/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-express/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/rejuvenecedor-de-babilonia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/vinoterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/circuito-relax-la-pausa-lunes-a-jueves/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/circuito-infantil-la-pausa-viernes-a-domingos-y-festivos/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/relax-para-dos/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/circuito-infantil-la-pausa-lunes-a-jueves/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/masaje-relax-aromaterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/circuito-relax-la-pausa-viernes-a-domingos-y-festivos/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/olivaterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/chocolate-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/indian-rituals/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/antigua-reina-de-egipto/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/masaje-deportivo/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/circuito-spa-brunch/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/spa-la-pausa-en-paris/masaje-pies-cansados/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/pack-ritual-parejas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/desconexion-con-spa-y-belleza-para-2/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/bienestar-con-spa-y-ritual-para-2/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/piscina-climatizada/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/ritual-aires-del-mediterraneo/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-masaje-y-gastronomia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-de-aromaterapia-50-minutos-y-circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/las-dunas-facial-deluxe-y-circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-desayuno/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-masaje/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-con-piedras-calientes-80-minutos-y-circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/vip-spa-suite/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-ritual/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/aire-de-hadas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/mountain-flower/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-tailandes/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-50/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-50/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-25/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-25/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/passage-du-silence/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/olive-flower-massage/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito-en-pareja/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-2-ninos/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-masaje-relajante-50-en-pareja/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-romance-de-sol-y-luna-circuito-en-pareja/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/oasis-de-serenidad-circuito/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/sprunch-para-6-personas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-geisha/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-con-piedras-calientes/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-ayurvedico-para-embarazadas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-masaje-relajante-o-descontracturante/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-detox/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-con-vitamina-c/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-de-chocolaterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-de-exfoliacion-e-hidratacion-tropical/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-desayuno/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-al-andalus/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas-masaje/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-luna-llena/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/reflexologia-podal/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-pureza/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-sunset-in-egypt/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-80-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-80-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-50-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-capricho-de-los-dioses/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-esplendor-radiante/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-las-vinas-de-la-toscana/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-40-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-40-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/swedish-massage/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/deep-tissue/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/stone-therapy/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/foot-reflexology/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/booster-vitamin-c/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/essences-senses/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-55-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-55-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/circuito-hydrowellness/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-90-min-adulto/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-masaje-podal/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-exfoliacion-e-hidratacion/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-chocolaterapia/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-masaje-del-peregrino/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-masaje-de-vela/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-60-min-adulto/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-masaje-relajante/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/leon/eurostars-via-de-la-plata/circuito-spa-masaje-deportivo/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-legs-relief/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/relax-renew-massage-60-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/hot-stones/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-shirodhara-abhyanga/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/peeling-massage-cocooning/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-corporal/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/signature-mediterranean-lifting-anti-aging/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-pinda-herbal-therapy-60-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/precious-pearl/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/anti-stress-back-remedy/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/mediterranean-bliss-face-body/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-facial/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/candle-night/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/circuito-spa-2-horas/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.path ?? "/spa-balneario/malaga/mood-spa/deep-tissue-60-min/",
    props: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.props ?? false,
    meta: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta || {},
    alias: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.alias || [],
    redirect: ServiceDetail_46page_45zVLkx6O_45chlbcsi34rnJWCYkIPTdgiCJgOaGsHxOukMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-c0a7c606-6136-4be1-a94c-753900250c85/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]